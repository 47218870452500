import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import logoSiteHeader from "/src/static/images/logo-site-header-1.svg";
import {gsap} from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

function SiteHeader() {
  const [menuActive, setMenuState] = useState(false);
  const [headerTransparent, setHeaderTransparent] = useState(false);

  useEffect(() => {
    gsap.to('.site-header', {
      scrollTrigger: {
        trigger: '.scrollTriggerLogo',
        start: '130',
        markers: false,
        onEnter: () => setHeaderTransparent(true),
        onLeaveBack: () => setHeaderTransparent(false),
      }
    });

    // returned function will be called on component unmount
    return () => {
      // kill all ScrollTrigger[s]
      ScrollTrigger.getAll().forEach(t => t.kill());
    }
  }, []);

  const skipToMain = (e) => {
    e.preventDefault();
    const mainContent = document.getElementById('main-content');
    gsap.to(window, { duration: 0.2, scrollTo: mainContent });
    mainContent.focus();
  };

  const handleMenuToggle = () => {
    setMenuState(!menuActive);
  };

  const handleMenuClose = () => {
    setMenuState(false);
  };

  return (
    <header
      className={`site-header ${menuActive ? "site-header-menu-active" : ""} ${headerTransparent && !menuActive ? "site-header--transparent" : ""}`}
    >
      <a
        href="#main-content"
        className="a11y-skip-to-main"
        onClick={skipToMain}
      >
        Skip to Main Content
      </a>
      <div className="container">
        <nav className="site-header-nav">
          <ul className="site-header-list-logo">
            <li className="level-1-logo">
              <Link
                to="/"
                className="site-header-logo"
                onClick={handleMenuClose}
              >
                <img
                  src={logoSiteHeader}
                  alt="Fantastic Fish Logo"
                  className="site-header-logo-img"
                />
              </Link>
            </li>
          </ul>
          <div className="mobile-nav-toggle">
            <button
              className={`site-header-hamburger site-header-hamburger--3dy ${
                menuActive ? "active" : ""
              }`}
              onClick={handleMenuToggle}
            >
              <span className="site-header-hamburger-box">
                <span className="site-header-hamburger-inner"></span>
              </span>
            </button>
          </div>
          <ul
            className={`site-header-list ${
              menuActive ? "" : "site-header-list--hide"
            }`}
          >
            <li className="level-1">
              <Link
                to="/"
                className="site-header-link"
                onClick={handleMenuClose}
              >
                HOME
              </Link>
            </li>
            <li className="level-1">
              <Link
                to="/about"
                className="site-header-link"
                onClick={handleMenuClose}
              >
                ABOUT
              </Link>
            </li>
            <li className="level-1">
              <Link
                to="/products"
                className="site-header-link"
                onClick={handleMenuClose}
              >
                PRODUCTS
              </Link>
            </li>
            <li className="level-1">
              <Link
                to="/contact"
                className="site-header-link btn btn-wire"
                onClick={handleMenuClose}
              >
                CONTACT
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default SiteHeader;
