import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SiteFooter from "./SiteFooter";
import SiteHeader from "./SiteHeader";


class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    // const { isPreloaded } = this.state;
    return (
      <>
        <SiteHeader/>
        {children}
        <SiteFooter/>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
