import React from "react";

function SiteFooter() {
  return (
    <footer className="site-footer">
      <div className="container">
        <p className="site-footer-text">
          Fantastic Fish Company<br/>
          7750 SW 117th Avenue, Suite 201<br/>
          Miami, Florida<br/>
          <a href="tel:3055961555" className="site-footer-link">Office: (305) 596 1555</a><br/>
          Fax: (305) 273 3831<br/>
        </p>
        <p className="site-footer-text site-footer-text--copyright">
          &copy;{(new Date().getFullYear())} Fantastic Fish Co. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default SiteFooter;
